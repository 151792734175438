







































import Vue from "vue";
import CreateUserModal from "@/app/components/modals/CreateUserModal.vue";
import PreviewUserModal from "@/app/components/modals/PreviewUser.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import pageHeader from "@/shared-components/BaPageHeader.vue";
import CredentialsModal from "@/app/components/modals/CredentialsModal.vue";
import UsersTab from "../../components/tabs/user-access/UsersTab.vue";
import CreatorsTab from "../../components/tabs/user-access/CreatorsTab.vue";
import ReviewersTab from "../../components/tabs/user-access/ReviewersTab.vue";
import AdminsTab from "../../components/tabs/user-access/AdminsTab.vue";
import SignatoriesTab from "../../components/tabs/user-access/SignatoriesTab.vue";
import ProfileTab from "../../components/tabs/user-access/ProfileTab.vue";
import DisabledUserTab from "../../components/tabs/user-access/DisabledUserTab.vue";

import { HOME } from "../../routes/endpoints";

export default Vue.extend({
    components: {
        UsersTab,
        CreatorsTab,
        ReviewersTab,
        CreateUserModal,
        AdminsTab,
        SignatoriesTab,
        pageHeader,
        ProfileTab,
        DisabledUserTab,
        PreviewUserModal,
        CredentialsModal,
    },
    data() {
        return {
            user: null,
            credUser: null,
            isEdit: false,
            isLoading: false,
            tabs: [
                { text: "All users & profiles", value: "users", component: "users-tab" },
                { text: "Admins", value: "admins", component: "admins-tab" },
                { text: "Reviewers", value: "reviewers", component: "reviewers-tab" },
                { text: "Certifiers", value: "creators", component: "creators-tab" },
                { text: "Disabled users", value: "disabled_users", component: "disabled-user-tab" },
            ],
            showModal: false,
        };
    },
    mounted() {
        // this.fetchUserList();
    },
    computed: {
        ...mapGetters({ profileData: "auth/getUserData" }),
        ...mapState("users", {
            signatories: (state: any) => state.levels?.find((level: any) => level.category === "ReportSignatory")?.users,
            reviewers: (state: any) => state.levels?.find((level: any) => level.category === "ReportReviewer")?.users,
            creators: (state: any) => state.levels?.find((level: any) => level.category === "ReportCreator")?.users,
        }),
        currentTab(): { text: string; value: string; component: string } {
            const { tab }: { tab?: string } = this.$route.query;

            const res = this.tabs.find((tabItem) => tabItem.value === tab);

            return res ?? this.tabs[0];
        },
        isPersonalProfileComplete(): boolean {
            return this.profileData.isProfileUpdated;
        },
        isCompanyProfileComplete(): boolean {
            return this.profileData.isCompanyProfileUpdated;
        },
        hasASignatory(): boolean {
            return this.signatories?.length > 1;
        },
        hasAReviewer(): boolean {
            return this.reviewers?.length > 0;
        },
        hasACreator(): boolean {
            return this.creators?.length > 0;
        },
    },
    methods: {
        ...mapActions({
            fetchUsers: "users/fetchUsers",
            fetchActiveUsers: "users/fetchActiveUsers",
            disableSingleUser: "users/deleteSingleUser",
            updateUserStatus: "users/updateUserStatus",
        }),
        isActiveTab(tab: string): boolean {
            return this.currentTab.value === tab;
        },
        toggleUpdate(user: any) {
            this.user = user;
            this.isEdit = true;
            this.$bvModal.hide("modal-view-user");
            this.$bvModal.show("modal-create-user");
        },
        toggleCreate() {
            this.user = null;
            this.isEdit = false;
            this.$bvModal.show("modal-create-user");
        },
        toggleView(user: any) {
            this.user = user;
            this.$bvModal.show("modal-view-user");
        },
        toggleCredentials(user: any) {
            this.credUser = user;
            this.$bvModal.show("modal-generate-credentials");
        },
        async disableUser(userId: string) {
            this.isLoading = true;
            try {
                await this.disableSingleUser(userId);
                this.$bvToast.toast("User disabled successfully", { title: `Success`, variant: "success", solid: true });
                await this.fetchUserList();
            } catch (error: any) {
                this.$bvToast.toast("Error", { title: error.message, variant: "danger", solid: true });
            } finally {
                this.isLoading = false;
            }
        },
        async fetchUserList() {
            const { tab } = this.$route.query;
            let pageTab = "";
            switch (tab) {
                case "users":
                    break;
                case "admins":
                    pageTab = "Admin";
                    break;
                case "reviewers":
                    pageTab = "Reviewer";
                    break;
                case "creators":
                    pageTab = "Certifier";
                    break;
                default:
                    pageTab = "";
            }
            this.isLoading = true;
            try {
                await this.fetchUsers(pageTab);
            } catch (error) {
                this.isLoading = false;
            } finally {
                this.isLoading = false;
            }
        },
        async loadAfterCompletion() {
            this.isLoading = true;
            this.$bvModal.hide("modal-create-user");
            await this.fetchUserList();
            // await this.fetchActiveUsers();
            this.isLoading = false;
            this.isEdit = false;

            const currentStep = Number(this.$route.query["onboarding-step"]);
            if (currentStep === 2 && !this.hasAReviewer) {
                this.$bvToast.toast("Next Add a Reviewer", { title: "Success!", variant: "success", solid: true });
            }
            if (currentStep === 2 && this.signatories?.length === 1) {
                this.$bvToast.toast("Next Add another Signatory", { title: "Success!", variant: "success", solid: true });
            }
            if (currentStep === 2 && this.hasASignatory && this.hasAReviewer) {
                this.showModal = true;
            }
        },
    },
});
