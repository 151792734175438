










































































import Vue from "vue";
// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from "dayjs";
import { required, requiredIf } from "vuelidate/lib/validators";
import { mapActions, mapGetters, mapState } from "vuex";

const defaultData = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    position: "",
    otherPosition: "",
    roles: [],
    dateOfAppointment: "",
    gender: "",
};

export default Vue.extend({
    name: "CreateUserModal",
    props: {
        edit: { type: Boolean, required: false, default: false },
        user: { type: Object, required: false, default: () => ({}) },
        userType: { type: String, default: "User" },
        defaultPosition: { type: String, default: "" },
        modalId: { type: String, required: true },
        preferredPositions: { type: Array, default: () => [] },
        hasPreferredPositions: { type: Boolean, default: false },
        showRoles: { type: Boolean, default: true },
    },
    watch: {
        edit(val) {
            if (val) {
                this.setForm(this.user);
            } else {
                this.form = { ...defaultData, position: this.defaultPosition };
            }
        },
        user(val) {
            this.setForm(val);
        },
    },
    computed: {
        ...mapGetters({
            positions: "company/companyPositionsText",
        }),
        companyP(): any {
            return this.companyPositions;
        },
        ...mapState("users", ["allRoles"]),
        updateText(): string {
            return this.edit ? "Update" : "Create";
        },
        updateDescription(): string {
            return this.edit ? "Update a" : "Add a new";
        },
        positionOptions() {
            return this.hasPreferredPositions ? [...this.preferredPositions] : [...(this.companyP as any)];
        },
    },
    data() {
        return {
            loading: false,
            isEdit: false,
            form: {
                ...defaultData,
                position: this.defaultPosition,
            },
            genders: [
                { id: 0, text: "Not Specified" },
                { id: 1, text: "Male" },
                { id: 2, text: "Female" },
            ],
            rolesArray: [
                { id: "ReportCertifier", text: "Report Certifier" },
                { id: "ReportReviewer", text: "Report Reviewer" },
                // { id: "ReportSignatory", text: "Report Signatory" },
                { id: "Admin", text: "Admin" },
            ],
            companyPositions: [
                "Compliance Officer",
                "Regulatory Officer",
                "Inspector",
                "Administrative Officer",
                "Analyst/Researcher",
                "Deputy Director",
                "Director General",
                "Executive Secretary",
                // "Others"
            ],
        };
    },
    async mounted() {
        await this.fetchRoles();
        this.rolesArray = this.allRoles.map((item: any) => ({
            ...item,
            id: item.id,
            text: item.name,
        }));
    },
    methods: {
        ...mapActions({
            saveUser: "users/createUser",
            createRole: "users/createRole",
            fetchUserProfile: "users/fetchSingleUser",
            updateUser: "users/updateSingleUser",
            updateUserRoles: "users/updateSingleUserRoles",
            fetchRoles: "users/fetchRoles",
        }),
        closeModal() {
            this.resetData();
            this.$bvModal.hide(`modal-${this.modalId}`);
        },
        resetData() {
            this.isEdit = false;
            this.$emit("resetEdit");
            this.form = {
                ...defaultData,
                position: this.defaultPosition,
            };
        },
        async fetchUser(userId: string) {
            this.isEdit = true;
            const user = await this.fetchUserProfile(userId);
            console.log("fetch user", {
                ...user,
                fullName: `${user.firstName} ${user.lastName}`,
            });
            this.setForm({
                ...user,
                fullName: `${user.firstName} ${user.lastName}`,
            });
        },
        inPositions(position: string) {
            return !this.hasPreferredPositions ? this.companyPositions.includes(position) : this.preferredPositions.includes(position);
        },
        setForm(payload: any) {
            const [firstName, lastName] = payload.fullName.split(" ");
            const dateOfAppointment = dayjs(payload.dateOfAppointment).format("YYYY-MM-DD") as any;
            this.form = {
                id: payload.id,
                firstName,
                lastName,
                email: payload.email,
                phoneNumber: payload.phoneNumber,
                position: this.inPositions(payload.position) ? payload.position : "Others",
                otherPosition: payload.position,
                // roles: [],
                roles: (this.rolesArray.filter((role) => payload?.roles?.includes(role.text)) || []) as any,
                gender: payload.gender,
                dateOfAppointment,
            };
        },

        async createUser() {
            if (this.form.roles.length === 0) {
                this.$bvToast.toast("Error", { title: "Role is required to create a user", variant: "danger", solid: true });
                return;
            }
            this.loading = true;
            const {
                position,
                otherPosition,
                roles,
                // dateOfAppointment,
                ...payload
            } = this.form;
            const roleBody = roles.map((item: any) => item.id);
            const body = {
                ...payload,
                roleIds: roleBody,
                position: position === "Others" ? otherPosition : position,
            };
            try {
                if (this.edit || this.isEdit) {
                    await this.updateUser({ userId: this.isEdit ? this.form.id : this.user.id, body });
                    this.fetchUser(this.form.id);
                    await this.updateUserRoles({ userId: this.isEdit ? this.form.id : this.user.id, body: roleBody });
                } else {
                    await this.saveUser({ ...body, sendCredentails: true });
                }
                this.$bvToast.toast("Success", { title: `User ${this.isEdit ? "update" : this.updateText}d successfully!`, variant: "success", solid: true });
                this.$emit("completed");
                this.resetData();
                this.$v.$reset();
            } catch (e) {
                this.$bvToast.toast("Error", { title: `Unable to ${this.updateText} User!`, variant: "danger", solid: true });
            } finally {
                this.loading = false;
            }
        },
    },
    validations(): any {
        return {
            form: {
                firstName: { required },
                lastName: { required },
                email: { required },
                phoneNumber: { required },
                position: { required },
                dateOfAppointment: { required },
                gender: { required },
                otherPosition: {
                    required: requiredIf(({ position }) => position === "Others"),
                },
                roles: {
                    $each: {
                        id: { required },
                    },
                },
            },
        };
    },
});
