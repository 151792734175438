














































import Vue from "vue";
import { mapActions, mapState } from "vuex";
import { formatDate } from "@/utils/helpers";

export default Vue.extend({
    name: "credentialsModal",
    props: {
        user: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    data(): any {
        return {
            isLoading: false,
            loading: false,
        };
    },
    computed: {
        ...mapState("users", ["userCredentials"]),
        userCredentialsInfo(): string {
            return `User Id: ${this.userCredentials.username} Password: ${this.userCredentials.password}`;
        },
    },
    watch: {
        async user(val) {
            try {
                this.isLoading = true;
                await this.sendUserCredentials(val.id);
                this.isLoading = false;
            } catch (error: unknown) {
                this.isLoading = false;
            }
        },
    },
    methods: {
        formatDate,
        ...mapActions("users", ["sendCredentials"]),
        onCopyCode() {
            this.$bvToast.toast("User credentials copied successfully", { title: `Success`, variant: "success", solid: true });
        },
        async sendUserCredentials() {
            try {
                this.loading = true;
                const res = await this.sendCredentials(this.user.id);
                this.loading = false;
                this.$bvToast.toast(res.message, { title: `Success`, variant: "success", solid: true });
            } catch (error) {
                this.loading = false;
            }
        },
    },
});
