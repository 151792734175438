





























































































import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import MoreInfo from "@/app/components/modals/MoreInfo.vue"

export default Vue.extend({
    components: {MoreInfo},
    props: {
        isLoadingOutside: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isLoading: false,
            currentPage: 1,
            perPage: 10,
            rows: 100,
            search: "",
            fields: [
                {
                    key: "fullName",
                    label: "Details",
                    tdClass: "align-middle pl-4",
                    thClass: "align-middle pl-4",
                },
                {
                    key: "position",
                    label: "Position",
                    tdClass: "align-middle text-center",
                    thClass: "align-middle text-center",
                },
                {
                    key: "roles",
                    label: "Role(s)",
                    tdClass: "align-middle text-center",
                    thClass: "align-middle text-center",
                },
                {
                    key: "entry_status",
                    label: "",
                    tdClass: "align-middle",
                    thClass: "align-middle",
                },
                {
                    key: "action",
                    label: "",
                    tdClass: "align-middle",
                    thClass: "align-middle",
                },
            ],
            items: [],
        };
    },
    computed: {
        ...mapGetters({
            allUsers: "users/allUsers",
        }),
        ...mapState("users", ["users"]),
        ...mapState("users", {
            creators: (state: any) =>
                state.levels?.find(
                    (level: any) => level.category === "ReportCreator",
                )?.users,
        }),
        ...mapState("users", {
            reviewers: (state: any) =>
                state.levels?.find(
                    (level: any) => level.category === "ReportReviewer",
                )?.users,
        }),
        ...mapState("users", {
            signatories: (state: any) =>
                state.levels?.find(
                    (level: any) => level.category === "ReportSignatory",
                )?.users,
        }),
        hasAReviewer(): boolean {
            return this.reviewers?.length > 0;
        },
        hasASignatory(): boolean {
            return this.signatories?.length > 1;
        },
        isRoleMissing(): boolean {
            return !this.hasAReviewer && !this.hasASignatory
        },
        defaultActions() {
            return [
                {
                    text: "User details",
                    action: (item: any) => {
                        this.$emit("toggleUserView", item);
                    },
                },
                {
                    text: "Edit User & User Role(s)",
                    action: (item: any) => {
                        this.$emit("toggleUpdate", item);
                    },
                },
                {
                    text: "Generate new credentials",
                    action: (item: any) => {
                        this.$emit("toggleCredentials", item);
                    },
                },
                {
                    text: "Disable User",
                    action: (item: any) => {
                        this.disableUser(item.id);
                    },
                },
            ];
        },
        makeActions(): any[] {
            return [];
        },
        // activeUsers(): any {
        //     return this.users as any
        // }
    },
    methods: {
        ...mapActions({
            fetchLevels: "users/fetchLevels",
            fetchUsers: "users/fetchUsers",
            fetchActiveUsers: "users/fetchActiveUsers",
            sendCredentials: "users/sendUserCredentials",
            fetchPositions: "company/fetchPositions",
            makeCreator: "reports/makeCreator",
            makeReviewer: "reports/makeReviewer",
            makeSignatory: "reports/makeSignatory",
            updateUserRoles: "users/updateSingleUserRoles",
            disableSingleUser: "users/deleteSingleUser",
            updateUserStatus: "users/updateUserStatus"
        }),
        
        toggleInfoModal() {
            this.$bvModal.show("modal-more-info");
        },
        getActions(roles: Array<string>): any {
            if (roles) {
                const userMakeActions = this.makeActions.filter(
                    (makeAction) => !roles.includes(makeAction.id),
                );
                return [...this.defaultActions, ...userMakeActions];
            }
            return [...this.defaultActions, ...this.makeActions];
        },
        async disableUser(userId: string) {
            this.isLoading = true;
            try {
                await this.disableSingleUser(userId);

                this.$bvToast.toast("User disabled successfully", {
                    title: `Success`,
                    variant: "success",
                    solid: true,
                });
                await this.fetchUserList();
            } catch (error: any) {
                this.$bvToast.toast("Error", {
                    title: error.message,
                    variant: "danger",
                    solid: true,
                });
            } finally {
                this.isLoading = false;
            }
        },
        async updateRole({
            item,
            roles,
            userType,
        }: {
            item: any;
            roles: Array<string>;
            userType: string;
        }) {
            const { id } = item;

            const body = [...roles];
            this.isLoading = true;
            try {
                await this.updateUserRoles({
                    userId: id,
                    body,
                });
                this.$bvToast.toast("Success", {
                    title: `Made ${userType} successfully`,
                    variant: "success",
                    solid: true,
                });
                await this.fetchUserList();
                // await this.fetchLevels();
            } catch (error) {
                this.$bvToast.toast("Error", {
                    title: `Unable to make ${userType}!`,
                    variant: "danger",
                    solid: true,
                });
            } finally {
                this.isLoading = false;
            }
        },
        async sendUserCredentials(userId: string) {
            //
            try {
                this.isLoading = true;
                const res = await this.sendCredentials(userId);
                // console.log(res);
                this.$bvToast.toast(res.message, {
                    title: `Success`,
                    variant: "success",
                    solid: true,
                });
            } catch (error) {
                this.isLoading = false;
                // this.$bvToast.toast("Error", {
                //     title: `Unable to send credentials`,
                //     variant: "danger",
                //     solid: true,
                // });
            } finally {
                this.isLoading = false;
            }
        },
        async fetchCompanyPositions() {
            this.isLoading = true;
            try {
                await this.fetchPositions();
            } catch (error) {
                // return error;
            }
            this.isLoading = false;
        },
        async fetchUserList() {
            this.isLoading = true;
            try {
                await this.fetchUsers("");
                // await this.fetchActiveUsers();
            } catch (error) {
                this.isLoading = false;
            } finally {
                this.isLoading = false;
            }
        },
    },
    mounted() {
        this.fetchUserList();
        // this.fetchCompanyPositions();
    },
});
