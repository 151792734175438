














import BaBadge from "@/shared-components/BaBadge.vue";
import Vue from "vue";

export default Vue.extend({
    components: { BaBadge },
    name: "MoreInfo",
    data() {
        return {
            roles: [
                { title: "Admin", description: "A user that has administrative rights to the system. They can edit a company’s information, and also add/remove/edit users on the system." },
                { title: "Reviewer", description: "A user that reviews and approve reports before it goes to the signatories’ desk." },
                { title: "Certifier", description: "A user that ratifies the compliance report generated from the report reviewer, before it goes out to the reporting entity." },
            ],
        };
    },
    computed: {},
    methods: {},
});
