
























































































import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default Vue.extend({
    props: {
        isLoadingOutside: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isLoading: false,
            currentPage: 1,
            perPage: 10,
            rows: 100,
            search: "",
            rolesArray: [
                "ReportCreator",
                "ReportReviewer",
                "ReportSignatory",
                "Admin",
            ],
            fields: [
                {
                    key: "details",
                    label: "Details",
                    tdClass: "align-middle pl-4",
                    thClass: "align-middle pl-4",
                },
                
                {
                    key: "position",
                    label: "Position",
                    tdClass: "align-middle text-center",
                    thClass: "align-middle text-center",
                },
                {
                    key: "roles",
                    label: "Role(s)",
                    tdClass: "align-middle text-center",
                    thClass: "align-middle text-center",
                },

                {
                    key: "entry_status",
                    label: "",
                    tdClass: "align-middle",
                    thClass: "align-middle",
                },
                {
                    key: "action",
                    label: "",
                    tdClass: "align-middle",
                    thClass: "align-middle",
                },
            ],
            items: [
                {
                    fullName: "Benjamin Samson",
                    email: "benjamin.sam@bodadmin.com",
                    imageUrl: "",
                    role: "Creator",
                    entry_status: null,
                },
                // {
                //     name: "Vivien Grace",
                //     email: "vivien.grace@bodadmin.com",
                //     imageUrl: "",
                //     roles: [
                //         {
                //             name: "Director",
                //         },
                //         {
                //             name: "Executive",
                //         },
                //     ],
                //     committees: 3,
                //     entry_status: "Draft",
                // },
            ],
        };
    },
    computed: {
        ...mapState("users", {
            signatories: (state: any) =>
                state.levels?.find(
                    (level: any) => level.category === "ReportSignatory",
                )?.users,
        }),
        ...mapGetters({
            levels: "users/levels",
        }),
        defaultActions() {
            return [
                {
                    text: "Edit",
                    action: (item: any) => {
                        this.$emit("toggleUpdate", item);
                    },
                },
                {
                    text: "Remove Signatory",
                    action: (item: any) => {
                        this.removeRole({
                            item,
                            roleType: "ReportSignatory",
                            userType: "Signatory",
                        });
                    },
                },
            ];
        },
        makeActions() {
            return [
                {
                    text: "Make Creator",
                    id: "ReportCreator",
                    action: (item: any) => {
                        this.updateRole({
                            item,
                            roles: [...item.roleIds, "ReportCreator"],
                            userType: "Creator",
                        });
                    },
                },
                {
                    text: "Make Reviewer",
                    id: "ReportReviewer",
                    action: (item: any) => {
                        this.updateRole({
                            item,
                            roles: [...item.roleIds, "ReportReviewer"],
                            userType: "Reviewer",
                        });
                    },
                },
                {
                    text: "Make Signatory",
                    id: "ReportSignatory",
                    action: (item: any) => {
                        this.updateRole({
                            item,
                            roles: [...item.roleIds, "ReportSignatory"],
                            userType: "Signatory",
                        });
                    },
                },
                {
                    text: "Make Admin",
                    id: "Admin",
                    action: (item: any) => {
                        this.updateRole({
                            item,
                            roles: [...item.roleIds, "Admin"],
                            userType: "Admin",
                        });
                    },
                },
            ];
        },
    },
    methods: {
        ...mapActions("users", ["fetchLevels"]),
        ...mapActions({
            fetchUsers: "users/fetchUsers",
            fetchPositions: "company/fetchPositions",
            makeCreator: "reports/makeCreator",
            makeReviewer: "reports/makeReviewer",
            makeSignatory: "reports/makeSignatory",
            updateUserRoles: "users/updateSingleUserRoles",
        }),
        getActions(roleIds: Array<string>): any {
            if (roleIds) {
                const userMakeActions = this.makeActions.filter(
                    (makeAction) => !roleIds.includes(makeAction.id),
                );
                return [...this.defaultActions, ...userMakeActions];
            }
            return [...this.defaultActions, ...this.makeActions];
        },
        async updateRole({
            item,
            roles,
            userType,
        }: {
            item: any;
            roles: Array<string>;
            userType: string;
        }) {
            const { id } = item;

            const body = [...roles];
            this.isLoading = true;
            try {
                await this.updateUserRoles({
                    userId: id,
                    body,
                });
                this.$bvToast.toast("Success", {
                    title: `Made ${userType} successfully`,
                    variant: "success",
                    solid: true,
                });
                await this.fetchSignatoriesList();
            } catch (error) {
                this.$bvToast.toast("Error", {
                    title: `Unable to make ${userType}!`,
                    variant: "danger",
                    solid: true,
                });
            } finally {
                this.isLoading = false;
            }
        },
        async fetchSignatoriesList() {
            this.isLoading = true;
            try {
                await this.fetchLevels();
                // console.log(this.signatories);
            } catch (error) {
                this.isLoading = false;
            } finally {
                this.isLoading = false;
            }
        },
        async removeRole({
            item,
            roleType,
            userType,
        }: {
            item: any;
            roleType: string;
            userType: string;
        }) {
            const { id } = item;

            const body = item.roleIds.filter(
                (role: string) => role !== roleType,
            );
            this.isLoading = true;
            try {
                await this.updateUserRoles({
                    userId: id,
                    body,
                });
                this.$bvToast.toast("Success", {
                    title: `Remove ${userType} successfully`,
                    variant: "success",
                    solid: true,
                });
                await this.fetchSignatoriesList();
            } catch (error) {
                this.$bvToast.toast("Error", {
                    title: `Unable to make ${userType}!`,
                    variant: "danger",
                    solid: true,
                });
            } finally {
                this.isLoading = false;
            }
        },
        async removeSignatory(id: string) {
            const currentIds = this.signatories.map(
                (creator: any) => creator.id,
            );
            try {
                this.isLoading = true;
                await this.makeSignatory([
                    ...currentIds.filter(
                        (currentId: string) => currentId !== id,
                    ),
                ]);
                await this.fetchSignatoriesList();
            } finally {
                //
                this.isLoading = false;
            }
        },
    },
    mounted() {
        // load the call only if the options are empty

        // this.fetchSignatoriesList();
    },
});
